* {
  box-sizing: border-box;
  
}

body{
  margin: 0;
  padding: 0;
}
.App{
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* position: relative; */
}