.projects{
    background-color: rgb(54, 2, 54);
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap:20px;
    padding: 20px;

    .projectsHeading{
        font-size: 30px;
        font-weight: 500;
        padding: 20px;
    }


    .onePtojectContainer{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 60%;

        @media only screen and (max-width:800px){
            
            width: 80%;
            
        }
        
    }
}