.skillCategory{
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        

    .skillcategoryImage{
        border-radius: 50%;
        background-color: rgb(233, 227, 227);
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        .icon{
            color: rgb(62, 62, 145);
            font-size: 30px;

        }

    }
    .skilltype{
        font-size: 18px;
        font-weight: 600;
    }

    hr{
        background-color: red;
        // border: 1px solid ;
        width: 100%;
    }
    .skills{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
}