.nav{
    background-color: rgb(54, 2, 54);
    color: white;
    display: flex;
    justify-content: space-between;
     position: relative;
    position: sticky;
    top: 0;

    @media only screen and (max-width:800px){   
        flex-direction: column;
    }

    .navLogo{
        display: flex;
         align-items: center;
        margin-left: 20px;
         height: 70px;
         cursor: pointer;
         

         @media only screen and (max-width:300px){   
            margin-left: 0px;
        }

        img{   
            height:55px; 
            @media only screen and (max-width:300px){   
                width: 220px;
            }  
        }
    }

    .togglebutton{
        position: absolute;
        top:25px;
        right: 20px;
        width: 30px;
        height: 22px;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    
        @media only screen and (max-width:800px){   
            display: flex;
        }

        span{
            border: 1px solid ;
        }
        
    }

    .navMenus{
        display: flex;
        align-items: center;
        cursor: pointer;

        a{
            text-decoration: none;
            color: white;
        }
        
        @media only screen and (max-width:800px){ 
            display: none;
            &.true{
                display: flex;
                flex-direction: column;
                
            }
            
        
        }

        
        .navMenuListItems{
            margin-right: 40px;
            font-size: 16px;
            padding: 7px 10px;
            
            &:hover{
                border-radius: 10px;
                background-color: rgb(62, 62, 145);
            }

            @media only screen and (max-width:800px){    
                 text-align: center;  
                margin-right: 0;  
                width: 100%; 
                
                
            }
        }
    }

    .navContactMe{
         display: flex;
         align-items: center;
        @media only screen and (max-width:800px){   
            width: 100%;  
            padding: 5px 10px; 
            display: none;
                &.true{
                    display: flex;
                    flex-direction: column;
                }  
                
        }
        
        button{
            background-color: rgb(113, 113, 200);
            color: white;
             padding: 10px 15px;
            border-radius: 5px;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;

            @media only screen and (max-width:800px){   
                width: 100%;  
                margin-right:0; 
                   
            }

            &:hover{
                background-color: rgb(62, 62, 145);
            }
        }
    }
}