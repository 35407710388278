.skills{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    

    .skillHeading{
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .skillCtegories{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap:20px;
        width: 80%;
        
    }
}