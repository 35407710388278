.contactme{
    width: 80%;
    display: flex;
    flex-direction: column; 
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;  
    margin: auto; 

    

    .contactmeHEading{
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .contactmeItems{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width:800px){   
            flex-direction: column;
        }

        .contactmeCard{
            width:32%;
            padding: 10px 20px ;
            border: none;
            box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.28);
            -webkit-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.28);
            -moz-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.28);
            transition: 0.2s ;
            text-decoration: none;
            color: rgb(62, 62, 145);;

                &:hover{
                    box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.36);
                    -webkit-box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.36);
                    -moz-box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.36);
                    transform: scale(1.03);
                    
                }

            @media only screen and (max-width:800px){   
                width:100%;
            }
            .contactCardTop{
                display: flex;
                align-items:center ;
                padding:0 10px ;

                .contactCardImage{
                    margin-right: 10px;
                    border-radius: 50%;
                    padding: 15px;
                    background-color: rgb(244, 237, 237);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon{
                        color: rgb(62, 62, 145);
                        font-size: 26px;
                    }
                    
                }
                .contactCardHeading{
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .contactCardBottom{
                padding:5px 10px;
                font-size: 14px;
            }
        }
    }
}