.oneproject{
    width: 400px;
    margin-bottom: 40px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    height: auto;

    @media only screen and (max-width:800px){   
        width: 100%;
    }
    
    
    img{
        width: 100%;
        height: 200px;
        
        -webkit-box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.72); 
box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.72);
    }

    .projectname{
        font-size: 26px;
        font-weight: 500;
        padding: 20px 20px 0 20px;
        
    }
    .projecttype{
        padding: 0px 20px;
        font-size: 20px;
        font-weight: 500;
        color: rgb(73, 72, 72);
    }
    .projectdescription{
        padding: 5px 20px 0 20px;
    }
        
    .technology{
        padding: 5px 20px 0 20px;
        color: rgb(73, 72, 72);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;

        .heading{
            font-size: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            
            
        }
        span{
            background-color: rgb(213, 205, 205);
            font-size: 14px;
            color: rgb(73, 72, 72);
            padding: 0px 10px 2px 10px;
              margin-top:7px;
            border-radius: 20px;
        }
    }

    .buttons{
        padding: 20px 20px;

        .buttonDemo{
            background-color: rgb(248, 242, 242);
            margin-right: 10px;
            padding: 10px;
            border: none;
            border: 0.1px solid lightgray;
            border-radius: 2px;
            font-weight: 700;
            transition: 0.2s ;
            cursor: pointer;

            &:hover{
                box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.61);
-webkit-box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.61);
-moz-box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.61);
                transform: scale(1.03);
            }
        }
        .buttonSourceCode{
            background-color: rgb(113, 113, 200);
            padding: 10px;
            color: white;
            border: none;
            border-radius: 2px;
            font-weight: 700;
            transition: 0.2s ;
            cursor: pointer;

            &:hover{
                background-color: rgb(62, 62, 145);
                transform: scale(1.03);
                box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.61);
-webkit-box-shadow: 3px 4px 5px -1px rgba(109, 17, 17, 0.61);
-moz-box-shadow: 3px 4px 5px -1px rgba(0,0,0,0.61);
            }
        }
    }
}

