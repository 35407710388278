.about{
    background-color: rgb(54, 2, 54);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    
    

    .aboutHeading{
        font-size: 30px;
        margin-bottom: 20px;

    }
    .aboutContent{
        width: 60%;
        display: flex;
        align-items: center;

        @media only screen and (max-width:800px){
            flex-direction: column;
            gap: 20px;
            width: 80%;
            
        }


        .aboutContentLeft{
            width: 50%;

            @media only screen and (max-width:800px){
                width: 100%;
                
            }
            img{
                height: 300px;
                width: 100%;
            }
        }
        .aboutContentRight{
            width: 50%;
            padding: 20px;
            font-size: 17px;

            @media only screen and (max-width:800px){
                width: 100%;
                
            }
        }
    }
}