.info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: auto;
   
    

    @media only screen and (max-width:800px){
        flex-direction: column;
        gap: 20px;
        
    }

    .left{
        // padding:20px;
        width: 50%;
        @media only screen and (max-width:800px){
            width: 90%;
        }
        
        .leftHeading{
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 5px;
            span{
                color: rgb(113, 113, 200);  
            }
        }
        .leftContent{
            color: grey;
            font-size: 16px;
            margin-bottom: 10px;
        }
        button{
            background-color: rgb(113, 113, 200);
            color: white;
            padding: 10px 15px;
            border-radius: 5px;
            font-size: 14px;
            border: none;
            cursor: pointer;
            &:hover{
                background-color: rgb(62, 62, 145);
            }
        }
    }
    .right{
        
        width: 50%;
        @media only screen and (max-width:800px){
            width: 90%;
        }
        .rightImage{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                height: 400px;
                
            }
        }
    }
   
}