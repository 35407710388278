.ScrollToTop{
    width: 50px;
    height: 50px;
    margin: 0;
    position: sticky;
    // left:93%;
    bottom: 40px;
    background-color: grey;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
}